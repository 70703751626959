import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container, ListGroup, Carousel } from "react-bootstrap"

const CollectionsPage = ({data}) => (
  <Layout pageInfo={{ pageName: "collections" }} active="collection">
    <SEO title="Collections" keywords={[`unhistoried`, `collections`, `brrrlabs`]} />
    <Container className="collections-page">
      <Row noGutters className="unhistoried-collections-row">
        {data.allContentfulCollection.nodes.map((item, index) => {
          return (
            <Col md={4} className="unhistoried-collections-col">
              <Link to={"/collection/"+item.collectionTitle}>
              <div className="unhistoried-collections-item">
                <h4 className="unhistoried-collections-title">{item.collectionTitle}</h4>
                <GatsbyImage className="unhistoried-collections-thumbnail" image={item.collectionFeaturedImage.gatsbyImageData} imgStyle={{objectFit:`contain`}}/>
              </div>
              </Link>
            </Col>
          )
        })}
      </Row>
    </Container>
  </Layout>
)

export const collectionQuery = graphql`
query CollectionQuery {
  allContentfulCollection(sort: {order: DESC, fields: createdAt}) {
    nodes {
      collectionFeaturedImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
      collectionTitle
    }
  }
}`

export default CollectionsPage
